var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "back-to-home rounded d-none d-sm-block"
  }, [_c('button', {
    staticClass: "btn btn-icon btn-soft-primary",
    on: {
      "click": _vm.logout
    }
  }, [_c('key-icon', {
    staticClass: "icons"
  })], 1)]), _c('section', {
    staticClass: "bg-home d-flex align-items-center"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-lg-5 col-md-6"
  }, [_c('div', {
    staticClass: "card login-page bg-white shadow rounded border-0"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h4', {
    staticClass: "card-title text-center mb-0"
  }, [_vm._v(_vm._s(_vm.$t("identity-verification")))]), _c('p', {
    staticClass: "text-center"
  }, [_c('small', {
    staticClass: "text-center text-muted",
    staticStyle: {
      "font-size": "9pt",
      "line-height": "2px"
    }
  }, [_vm._v(_vm._s(_vm.$t("check-info")))])]), _c('form', {
    staticClass: "login-form mt-4",
    on: {
      "submit": function ($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.onSubmit($event);
      }
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("fullname")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.name,
      expression: "form.name"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "",
      "required": ""
    },
    domProps: {
      "value": _vm.form.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "name", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-lg-8"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_vm._m(1), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.ic_number,
      expression: "form.ic_number"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "required": ""
    },
    domProps: {
      "value": _vm.form.ic_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "ic_number", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-lg-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("gender")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.gender,
      expression: "form.gender"
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.form, "gender", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "Male"
    }
  }, [_vm._v(_vm._s(_vm.$t("male")))]), _c('option', {
    attrs: {
      "value": "Female"
    }
  }, [_vm._v(_vm._s(_vm.$t("female")))])])])]), _c('div', {
    staticClass: "col-lg-12"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$("address")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.address,
      expression: "form.address"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "required": ""
    },
    domProps: {
      "value": _vm.form.address
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "address", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-lg-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$("postcode")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.postcode,
      expression: "form.postcode"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "required": ""
    },
    domProps: {
      "value": _vm.form.postcode
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "postcode", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-lg-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("city")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.city,
      expression: "form.city"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "required": ""
    },
    domProps: {
      "value": _vm.form.city
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "city", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-lg-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$("state")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.state,
      expression: "form.state"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "required": ""
    },
    domProps: {
      "value": _vm.form.state
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "state", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-lg-6"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("nationality")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.nationality,
      expression: "form.nationality"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "required": ""
    },
    domProps: {
      "value": _vm.form.nationality
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "nationality", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-lg-6"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("religion")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.religion,
      expression: "form.religion"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "required": ""
    },
    domProps: {
      "value": _vm.form.religion
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "religion", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-lg-12 mb-0"
  }, [_c('button', {
    staticClass: "btn btn-primary btn-block spinner spinner-light spinner-right"
  }, [_vm._v(" " + _vm._s(_vm.$t("save")) + " "), _vm.isLogin ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _vm._e()])])])])])])])])])]), _c('Switcher')], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-7 col-md-6"
  }, [_c('div', {
    staticClass: "mr-lg-5"
  }, [_c('img', {
    staticClass: "img-fluid d-block mx-auto",
    attrs: {
      "src": "/images/user/login.svg",
      "alt": ""
    }
  })])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', [_vm._v(" MyKad "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]);
}]

export { render, staticRenderFns }